<template>
  <b-card-code
    title="Courses"
    no-body
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    
    <b-col
      md="12"
      class="my-1"
    >
      <b-button    
        variant="primary"
        class="mb-1 float-right"
        to="/create-course"
      >
            Course Material Listing
      </b-button>
    </b-col>
    <b-table
      :fields="fields"
      :items="materialLists"
      responsive
      class="mb-0"
    >

      <!-- A virtual column -->
      <template #cell(Sr#)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(attachment_name)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>

      <template #cell(course)="data">
        <span class="text-nowrap">{{ data.value.name }}</span>
      </template>
      
      <template #cell(id)="data">
        <div class="text-nowrap">
            <b-button variant="outline-primary" :to="'/edit-course-video/'+data.value+''" size="sm"><b-icon icon="pencil-square"></b-icon></b-button>
        </div>
     </template>
    </b-table>
    <!-- pagination -->
    
        <b-col>
      
      
    <record-count :to ='toRecordCount' :from='fromRecordCount' :total='totalRecordCount'></record-count>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :base-url="path"
        :per-page="perPageRecord"
        :align="selected"
        @input = "changePage(currentPage)"
        class = "mb-4"
      />
    </b-col>
      
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BProgress,BButton, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol, BLink, BIcon} from 'bootstrap-vue'
import CourseService from '@/services/course.service.js';

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BLink,
    BIcon
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'Sr#',
        // A column that needs custom formatting
        { key: 'attachment_name', label: 'Attachment Name' },
        { key: 'course', label: 'Course' },
        { key: 'id', label: 'Action' },
      ],
      materialLists: [],
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      showOverlay: false,
      course_id: this.$route.params.courseId,
      month_id: this.$route.params.monthId,
    }
  },
   watch:{
        '$route'(to) {
            this.course_id = to.params.courseId;
            this.month_id = to.params.monthId;
        } 
    },
  methods: {
    getMaterial() {
        this.showOverlay = true;
        CourseService.getAttachment(
            this.currentPage,
            this.course_id,
            'video',
            this.month_id
        ).then(response => {
            this.materialLists = response.data.data.attachments.data;
            this.rows = response.data.data.attachments.total;
            this.currentPage = response.data.data.attachments.current_page
            this.path = response.data.data.attachments.path
            this.pageCount = response.data.data.attachments.last_page;
            this.currentPage = response.data.data.attachments.current_page;
            this.perPageRecord = response.data.data.attachments.per_page;
            this.toRecordCount = response.data.data.attachments.to;
            this.fromRecordCount = response.data.data.attachments.from;
            this.totalRecordCount = response.data.data.attachments.total;
            this.srCounter = (this.currentPage-parseInt(1)) * this.perPageRecord;
            this.showOverlay = false;
        }).catch(error => {
            this.showOverlay = false;
            console.log(error);
        });
    },
    changePage(event) {
      this.getCourses(this.currentPage);
    },
    deleteCourse(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showOverlay = true;
          UserService.delete(id).then(response => {
                    this.$swal({
                      icon: 'success',
                      title: 'Deleted!',
                      text: 'User has been deleted.',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  
                  const userIndex = this.studentLists.findIndex(user => user.id === id);
                  this.studentLists.splice(userIndex, 1);   
                  this.showOverlay = false;
                    
                }).catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
          
        }
      })
    }
  },
  created() {
    this.getMaterial(this.currentPage);
  }
}
</script>
